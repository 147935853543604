import * as React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import Hero from "../components/Hero";

const ServicesPage = ({ data }) => {
  const items = [
    {
      name: "Women's Haircut",
      description: "wash + style included",
      price: "$45 | 60 min",
      image: getImage(data.womensHaircut),
      alt: "creating fresh, trendy cuts that are tailored to what you want",
    },

    {
      name: "Men's Haircut",
      description: "wash + style included",
      price: "$30 | 45 min",
      image: getImage(data.mensHaircut),
      alt: "offering quick, specific cuts for your look whether classic or cutting edge",
    },

    {
      name: "Partial Highlight",
      description:
        "All color services include two bowls of product. If extra color is needed, it is an additional $10 per bowl.",
      price: "$140 | 135 min",
      image: getImage(data.partialHighlight),
      alt: "placing highlights in strategic areas adding to your look",
    },

    {
      name: "All Over Highlight",
      description:
        "All color services include two bowls of product. If extra color is needed, it is an additional $10 per bowl.",
      price: "$165 | 135 min",
      image: getImage(data.allOverHighlight),
      alt: "create a dramatic or subtle look with all over highlights",
    },

    {
      name: "Highlight + Root Touch-Up",
      description:
        "All color services include two bowls of product. If extra color is needed, it is an additional $10 per bowl.",
      price: "$130 | 105 min",
      image: getImage(data.highlightRootTouchUp),
      alt: "touching up your highlights to make them fresh again while keeping your hair healthy",
    },

    {
      name: "Color Touch-Up",
      description:
        "All color services include two bowls of product. If extra color is needed, it is an additional $10 per bowl.",
      price: "$100 | 75 min",
      image: getImage(data.colorTouchUp),
      alt: "a color touch up for any color",
    },

    {
      name: "Balayage",
      description:
        "All color services include two bowls of product. If extra color is needed, it is an additional $10 per bowl.",
      price: "$190 | 150 min",
      image: getImage(data.balayage),
      alt: "soft natural highlights make for a stunning, picture-worthy look",
    },

    {
      name: "All Over Color",
      description:
        "All color services include two bowls of product. If extra color is needed, it is an additional $10 per bowl.",
      price: "$130 | 90 min",
      image: getImage(data.allOverColor),
      alt: "create a new look with all over color",
    },
  ];

  return (
    <Layout>
      <Hero>
        <h2 className="font-light text-3xl mt-32 sm:mt-0 tracking-widest sm:text-5xl small-caps text-gray-300">
          Services
        </h2>
        <div className="grid max-w-6xl mt-8 grid-cols-1 sm:grid-cols-2 gap-8 px-4">
          {items.map(({ name, description, price, image, alt }) => (
            <div className="flex sm:flex-row flex-col justify-center items-center">
              <div className="w-1/2 sm:w-3/12">
                <GatsbyImage
                  className="w-full block h-auto"
                  alt={alt}
                  image={image}
                />
              </div>
              <div className="sm:w-9/12 font-thin mt-4 sm:mt-0 text-gray-300 sm:ml-4 text-center sm:text-left">
                <p className="text-2xl tracking-wide">{name}</p>
                <p className="py-4 max-w-xs sm:max-w-none">{description}</p>
                <p>{price}</p>
              </div>
            </div>
          ))}
        </div>
      </Hero>
    </Layout>
  );
};

export const Head = () => <Seo title="Services" />;

export const pageQuery = graphql`
  query ServicesQuery {
    allOverColor: file(
      relativePath: { eq: "hair-services/all-over-color.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    allOverHighlight: file(
      relativePath: { eq: "hair-services/all-over-highlight.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    balayage: file(relativePath: { eq: "hair-services/balayage.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    colorTouchUp: file(
      relativePath: { eq: "hair-services/color-touch-up.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    highlightRootTouchUp: file(
      relativePath: { eq: "hair-services/highlight-root-touch-up.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    mensHaircut: file(relativePath: { eq: "hair-services/mens-haircut.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    partialHighlight: file(
      relativePath: { eq: "hair-services/partial-highlight.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    womensHaircut: file(
      relativePath: { eq: "hair-services/womens-haircut.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;

export default ServicesPage;
